.container{
    display: flex;
    flex-direction: column;
    margin: auto;
    margin-top: 200px;
    width: 600px;
    background: #141b2d;
    padding-bottom: 30px;
    border-width: 2px;
    border-color: #4cceac;
    border-radius: 8px;
    border-style: solid;
}

.header{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 9px;
    width: 100%;
    margin-top: 30px;
}

.text{
    color: #fff;
    font-size: 48px;
    font-weight: 500;
}

.underline{
    width: 61px;
    height: 6px;
    background: #fff;
    border-radius: 9px;
}

.inputs{
margin-top: 55px;
display: flex;
flex-direction: column;
gap: 25px;
}

.input{
    display: flex;
    align-items: center;
    margin: auto;
    width: 480px;
    height: 80px;
    background: #eaeaea;
    border-color: #4cceac;
    border-radius: 8px;
    border-style: solid;
}

.input img{
    margin: 0px 30px;
}

.input input{
    height: 50px;
    width: 400px;
    background: transparent;
    border: none;
    outline: none;
    color: #797979;
    font-size: 19px;
}

.forgot-password{
    padding-left: 62px;
    margin-top: 27px;
    color: #797979;
    font-size: 18px;
}

.forgot-password span{
    color: #4cceac;
    cursor: pointer;
}

.submit-container{
    display: flex;
    gap: 30px;
    margin: 60px auto;
}

.submit{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 220px;
    height: 59px;
    color: #fff;
    background-color:  #4cceac;
    border-radius: 50px;
    font-size: 19px;
    font-weight: 600;
    cursor: pointer;
}

.gray{
    background: #eaeaea;
    color: #676767;
}